@keyframes navbarSlidein {
  0% {
    transform: scaleY(0);
    transform-origin: top;
  }

  100% {
    transform: scaleY(1);
    transform-origin: top;
  }
}

.cNavbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: $NAVBAR_HEIGHT;
  background: get-color('white', 50);
  box-shadow: 0 0 4px get-color('dark', 500, 0.5);
  z-index: 100;

  @include mq($from: md, $until: xl) {
    height: auto;
  }

  .container {
    position: relative;
  }

  //  ///////////////////////
  //  SECTION: BRAND
  .cNavbar__brand {
    $brandHeight: 37px;
    position: absolute;
    z-index: 110;

    top: ($NAVBAR_HEIGHT - $brandHeight) / 2;
    left: 15px;

    color: get-color('dark');

    outline: 3px dotted transparent;
    outline-offset: 3px;
    @include transition();

    height: $brandHeight;
    svg {
      height: $brandHeight;
    }

    &:focus {
      outline-color: get-color('dark');
    }
  }

  @include mq($until: md) {
    &.cNavbar--expanded {
      .cNavbar__brand {
        left: 50%;
        transform: translateX(-50%);

        border-color: transparent;
      }
    }
  }

  //  ///////////////////////
  //  SECTION: CTA
  .cNavbar__cta {
    text-align: right;
    padding-top: 10px;
  }

  //  ///////////////////////
  //  SECTION: COLLAPSE
  @include mq($until: md) {
    &:not(.cNavbar--expanded) .cNavbar__content {
      display: none;
    }

    &.cNavbar--expanded .cNavbar__content {
      animation: navbarSlidein 0.2s ease-in-out;
    }
  }

  @include mq($from: md) {
    .cNavbar__collapse {
      display: none;
    }
  }

  @include mq($until: md) {
    .cNavbar__collapse {
      --clr-primary: #{get-color('dark')};

      position: absolute;
      z-index: 110;

      top: ($NAVBAR_HEIGHT - 45px) / 2;
      right: ($NAVBAR_HEIGHT - 45px) / 2;
      margin-top: 5px;

      width: 50px;

      cursor: pointer;

      padding: 5px;
      transform: translateY(3.25px);

      &::after,
      &::before,
      & div {
        background-color: get-color('dark', 900);
        content: '';

        display: block;

        height: 1px;
        margin: 9px 0;

        transition: all 0.2s ease-in-out;
      }

      &::before {
        margin-top: 0;
      }

      &::after {
        margin-bottom: 0;
      }
    }

    &:not(.cNavbar--expanded) .cNavbar__collapse {
      &::before,
      &::after {
        background-color: get-color('dark', 900);
        transform: translateY(0) rotate(0);
      }

      & div {
        transform: scale(1);
      }
    }

    &.cNavbar--expanded .cNavbar__collapse {
      &::before {
        background-color: get-color('dark');
        transform: translateY(10px) rotate(135deg);
      }

      &::after {
        background-color: get-color('dark');
        transform: translateY(-10px) rotate(-135deg);
      }

      & div {
        transform: scale(0);
      }
    }
  }

  //  ///////////////////////
  //  SECTION: CONTENT
  .cNavbar__content {
    text-align: right;
    @include mq($from: md, $until: xl) {
      margin-top: 8px;
      text-align: center;

      ul {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;

        li:first-child {
          padding-left: 0;
        }

        li:last-child {
          padding-right: 0;
        }
      }
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        --clr-primary: #{get-color('dark')};

        @include transition();

        @include mq($from: md) {
          display: inline-block;
          height: $NAVBAR_HEIGHT;
          line-height: $NAVBAR_HEIGHT;
        }

        @include mq($from: md, $until: xl) {
          height: auto;
          line-height: 38px;
        }

        padding: 0 15px;

        &:not(.cNavbar__link--cta) a {
          position: relative;

          cursor: pointer;
          border: 0;
          background: none;
          font-size: 16px;
          line-height: 21px;
          font-weight: 400;
          color: get-color('dark', 900);

          &::after {
            @include transition();

            position: absolute;
            content: '';
            right: 0;
            bottom: -11px;
            left: 0;
            height: 1px;
            background: get-color('dark', 500);
            transform: scaleX(0);

            @include mq($until: md) {
              bottom: -6px;
            }

            @include mq($from: md, $until: xl) {
              $height: 3px;
              bottom: -10px - $height;
              height: $height;
              background: get-color('blue');
            }
          }
        }

        &:not(.cNavbar__link--cta).cNavbar__link--active a,
        &:not(.cNavbar__link--cta):hover a {
          color: get-color('dark');

          &::after {
            transform: scaleX(1);
          }
        }
      }
    }

    @include mq($until: md) {
      background: get-color('white', 50);

      padding-top: $NAVBAR_HEIGHT + 10px;
      padding-bottom: 20px;
      text-align: center;
      box-shadow: 0 0 20px rgba(get-color('dark'), 0.25);

      ul {
        li {
          line-height: 50px;

          a,
          button {
            font-size: 18px;
          }
        }
      }
    }
  }

  @include mq($until: md) {
    .container {
      margin: 0;
      padding: 0;

      width: 100%;
      max-width: 100%;
    }
  }
}
