.cCTA {
  position: relative;
  overflow: hidden;

  padding: 4rem 0;

  ._background {
    position: absolute;

    bottom: -20%;
    right: 0;
    height: 100%;
    width: 100%;

    background: get-color('white', 900);
  }

  .cCTA_card {
    position: relative;
    background: get-color('white', 50);
    border-radius: 10px;
    box-shadow: 0 0 34px rgba(38, 88, 145, 0.1);

    padding: 3rem 10rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    div + div {
      margin-left: 2rem;
    }

    h2 {
      margin-bottom: 1rem;
    }

    @include mq($from: lg, $until: xl) {
      padding: 3rem;
    }

    @include mq($until: lg) {
      text-align: center;
      flex-direction: column;
      padding: 2rem;

      div + div {
        margin-top: 2rem;
        margin-left: 0;
      }
    }
  }
}
