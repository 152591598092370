.buttons {
  margin-top: 4rem;
  display: flex;

  @include mq($until: md) {
    flex-direction: column;
    align-items: center;
  }

  .tButton + .tButton {
    margin-left: 1rem;
  }

  @include mq($until: md) {
    margin-top: 2rem;
    text-align: center;
    .tButton + .tButton {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
}
