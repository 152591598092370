footer.cFooter {
  color: get-color('white');
  background: get-color('dark', 500);

  padding-top: 5rem;
  @include mq($until: lg) {
    padding-top: 2rem;
  }

  .cFooter__content {
    ul {
      list-style: none;
      padding: 0;

      li {
        font-weight: 700;

        & + li {
          margin-top: 10px;
        }
      }
    }

    a.cFooter__content__brand {
      color: get-color('white');
      margin-bottom: 2rem;

      &:hover {
        color: get-color('blue', 100);
      }

      svg {
        max-width: 167px;
      }
    }

    a,
    p {
      & + a,
      & + p {
        margin-top: 1rem;
      }
    }

    @include mq($until: md) {
      .cFooter__separator {
        position: relative;
        padding-top: 1.5rem;

        &::before {
          position: absolute;
          content: '';
          top: 0;
          left: 15px;
          right: 15px;
          height: 1px;
          background: get-color('white');
        }
      }
    }
  }

  .cFooter__recaptcha,
  .cFooter__legals {
    background: get-color('dark', 600);
    margin-top: 2rem;
    padding: 1rem;

    color: getColor('secondary');

    display: flex;
    justify-content: center;

    p {
      margin: 0;
    }

    .link {
      font-weight: normal;
      @include mq($from: md) {
        margin-left: 1rem;
      }
    }

    @include mq($until: md) {
      flex-direction: column;

      .link {
        margin-top: 0.5rem;
      }
    }
  }

  .cFooter__legals {
    background: get-color('dark', 700);
    margin-top: 0;
    .link {
      @include mq($from: md) {
        margin-left: 0;
        & + .link {
          margin-left: 1rem;
        }
      }
      @include mq($until: md) {
        margin-top: 0;
        & + .link {
          margin-top: 0.5rem;
        }
      }
    }
  }

  #mds {
    background: get-color('dark', 800);
    margin-top: 0;
    padding: 1.5em 1em;

    text-align: center;

    a {
      --clr-primary: #b0b0b0;

      display: inline-block;
      position: relative;

      ._copyright,
      ._copyright__colored {
        font-size: 17px;
        font-weight: 600;

        @include mq($from: md) {
          transform: rotateX(0) translateZ(8.5px);
        }
        @include transition();

        color: #fff;

        ._copyright__code {
          padding-right: 0.5rem;
        }

        ._copyright__heart {
          padding-left: 0.5rem;
        }

        svg {
          transform: translateY(12.5%);
          path {
            fill: #fff;
            @include transition();
          }
        }

        span {
          color: getColor('white');
          @include transition();
        }
      }

      ._copyright__colored {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        transform: rotateX(90deg) translateZ(8.5px);

        ._copyright__code path {
          fill: #939393;
        }

        span {
          color: #fa6938;
        }

        ._copyright__heart path {
          fill: #cb1b74;
        }
      }

      &:focus,
      &:hover {
        @include mq($from: md) {
          ._copyright {
            transform: rotateX(-90deg) translateZ(8.5px);
          }

          ._copyright__colored {
            transform: rotateX(0) translateZ(8.5px);
          }
        }

        @include mq($until: md) {
          ._copyright span {
            color: #fa6938;
          }
        }
      }
    }
  }
}
