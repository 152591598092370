.legals {
  .tTitle:not(:first-child) {
    margin-top: 3rem;
  }

  h3,
  h4 {
    font-weight: 600;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul {
    margin-bottom: 1rem;
  }

  ._updatedOn {
    margin-top: 2rem;
    color: get-color('dark', 400);
  }

  ._footer {
    position: relative;

    margin-top: 2rem;
    padding-top: 2rem;

    display: flex;
    justify-content: space-between;
    @include mq($until: md) {
      flex-direction: column;
    }

    &::after {
      content: '';

      position: absolute;

      top: -1px;
      left: 0;
      width: 130px;

      height: 2px;

      background: get-color('dark');
    }

    ._right {
      display: flex;
      align-items: center;
      @include mq($until: md) {
        flex-direction: column;
        align-items: flex-start;
      }

      .link + .link {
        margin-left: 1rem;
      }

      @include mq($until: md) {
        margin-top: 1rem;
        .link {
          display: block;

          & + .link {
            margin-left: 0;
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
}
