.tInput {
  .tInput__group {
    position: relative;

    background: get-color('white', 50);
    border-radius: 5px;
    padding: 0.75rem 1rem;
    border: 1px solid get-color('dark', 100);

    @include transition();

    input,
    textarea {
      font-family: 'Roboto', sans-serif;
      background: none;
      border: unset;
      outline: none;
      width: 100%;
      font-size: 1rem;
      line-height: 21px;
      color: get-color('dark', 900);
      resize: none;

      @include transition();

      &::placeholder {
        font-family: 'Roboto', sans-serif;
        color: get-color('dark');
      }
    }

    .tInput__group__error {
      position: absolute;
      top: calc(0.75rem + 1px);
      right: 1rem;
      height: 21px;
      width: 21px;
      color: get-color('danger');
    }

    &.tInput__group--filled {
      border-color: get-color('dark', 900);
    }

    &.tInput__group--error {
      border-color: get-color('danger', 500);

      input {
        width: calc(100% - 25px);
      }
    }

    &:focus-within {
      border-color: get-color('blue');
      box-shadow: 0 0 5px get-color('blue');

      input,
      textarea {
        color: get-color('blue', 900);
      }

      &.tInput__group--error {
        border-color: get-color('danger');
        box-shadow: 0 0 5px get-color('danger');
      }
    }

    &.tInput__group--disabled {
      background: get-color('white', 200);
      border-color: get-color('white', 900);

      input,
      textarea {
        color: get-color('dark', 100);

        &::placeholder {
          color: get-color('dark', 100);
        }
      }
    }
  }

  .tInput__state {
    .tInput__state__error {
      color: get-color('danger', 700);
    }
  }
}
