.cHeader {
  padding-top: 4rem;
  padding-bottom: 4rem;
  color: get-color('white');
  text-align: center;

  svg {
    max-width: 240px;
  }

  h1 {
    margin: 1rem 0;
  }

  .tButton {
    margin-top: 2rem;
  }
}
