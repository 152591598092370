//  //////////////////////////////////////////////
//  SECTION: config-get
@function config-get($map, $key, $keys...) {
  @if type-of($map) != 'map' {
    @error 'You must provide a map !';
  }

  @if $key == '_fallback' {
    @error 'Cannot use fallback as key !';
  }

  @if not map-has-key($map, '_fallback') {
    @error 'No fallback !';
  }

  @if not $key {
    $key: map-get($map, '_fallback');
  }

  $value: map-get($map, $key);
  $fallbackValue: map-get($map, map-get($map, '_fallback'));

  @each $a in $keys {
    $fallbackValue: map-get($fallbackValue, $a);

    @if map-has-key($value, $a) {
      $value: map-get($value, $a);
    } @else {
      $value: $fallbackValue;
    }
  }

  @if not $value {
    @error 'Not found !';
  }

  @return $value;
}

//  //////////////////////////////////////////////
//  SECTION: tint
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

//  //////////////////////////////////////////////
//  SECTION: shade
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

//  //////////////////////////////////////////////
//  SECTION: map-deep-get
@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

//  //////////////////////////////////////////////
//  SECTION: map-deep-set
@function map-deep-set($map, $keys, $value) {
  $maps: ($map);
  $result: null;

  @if type-of(nth($keys, -1)) == 'map' {
    @warn "The last key you specified is a map; it will be overrided with `#{$value}`.";
  }

  @if length($keys) == 1 {
    @return map-merge(
      $map,
      (
        $keys: $value,
      )
    );
  }

  @for $i from 1 through length($keys) - 1 {
    $current-key: nth($keys, $i);
    $current-map: nth($maps, -1);
    $current-get: map-get($current-map, $current-key);
    @if $current-get == null {
      @error "Key `#{$key}` doesn't exist at current level in map.";
    }
    $maps: append($maps, $current-get);
  }

  @for $i from length($maps) through 1 {
    $current-map: nth($maps, $i);
    $current-key: nth($keys, $i);
    $current-val: if($i == length($maps), $value, $result);
    $result: map-merge(
      $current-map,
      (
        $current-key: $current-val,
      )
    );
  }

  @return $result;
}
