$C_LINK_THEMES: (
  '_fallback': 'dark-900',
  'white': (
    'base': get-color('white'),
    'disabled': get-color('white', 900),
    'hovered': get-color('blue', 100),
    'active': get-color('blue', 200),
  ),
  'dark-900': (
    'base': get-color('dark', 900),
    'disabled': get-color('black'),
    'hovered': get-color('dark', 700),
    'active': get-color('blue', 500),
  ),
  'dark': (
    'base': get-color('dark', 500),
    'disabled': get-color('black'),
    'hovered': get-color('dark', 900),
    'active': get-color('blue', 900),
  ),
  'black': (
    'base': get-color('black'),
    'disabled': get-color('black', 200),
    'hovered': get-color('dark'),
    'active': get-color('dark', 400),
  ),
  'success': (
    'base': get-color('success'),
    'disabled': get-color('success', 600),
    'hovered': get-color('success', 200),
    'active': get-color('success', 100),
  ),
  'danger': (
    'base': get-color('danger'),
    'disabled': get-color('danger', 600),
    'hovered': get-color('danger', 400),
    'active': get-color('danger', 400),
  ),
  'warning': (
    'base': get-color('warning'),
    'disabled': get-color('warning', 600),
    'hovered': get-color('warning', 400),
    'active': get-color('warning', 400),
  ),
  'info': (
    'base': get-color('info'),
    'disabled': get-color('info', 600),
    'hovered': get-color('info', 400),
    'active': get-color('info', 400),
  ),
);

a.link {
  font-size: 700;

  @include mq($until: lg) {
    a {
      text-decoration: underline;
    }
  }

  &.link--medium {
    font-weight: 500;
  }

  &.link--underline,
  &:hover {
    text-decoration: underline;
  }

  &.link--disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  @include transition();

  @each $key, $value in $C_LINK_THEMES {
    @if $key != '_fallback' {
      &.link--#{$key} {
        $base: config-get($C_LINK_THEMES, $key, 'base');
        $disabled: config-get($C_LINK_THEMES, $key, 'disabled');
        $hovered: config-get($C_LINK_THEMES, $key, 'hovered');
        $active: config-get($C_LINK_THEMES, $key, 'active');

        --clr-primary: #{$hovered};

        color: $base;

        &.link--disabled {
          color: $disabled;
        }

        &:hover {
          color: $hovered;
        }

        &:active {
          color: $active;
        }
      }
    }
  }
}
