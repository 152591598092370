$C_CARD_COLORS: (
  '_fallback': 'dark',
  'white': (
    background: get-color('white', 50),
    color: get-color('dark', 900),
  ),
  'dark': (
    background: get-color('dark'),
    color: get-color('white'),
  ),
);

.card {
  padding: 2.5rem;

  background: config-get($C_CARD_COLORS, null, 'background');
  color: config-get($C_CARD_COLORS, null, 'color');

  &.card--rounded {
    border-radius: 10px;
  }

  @include mq($from: md) {
    &.card--large {
      padding: 4rem 8rem;
    }
  }

  &.card--centered {
    text-align: center;
  }

  &.card--shadow {
    box-shadow: 0 0 34px get-color('dark', 500, 0.1);
  }

  @each $key, $value in $C_CARD_COLORS {
    @if $key != '_fallback' {
      &.card--#{$key} {
        $background: config-get($C_CARD_COLORS, $key, 'background');
        $color: config-get($C_CARD_COLORS, $key, 'color');

        background: $background;
        color: $color;
      }
    }
  }
}
