#vQuelsLitiges {
  section#content {
    position: relative;

    overflow: hidden;

    padding: 6rem 0;

    @include mq($until: lg) {
      padding: 3rem 0;
    }

    ._background {
      position: absolute;
      top: 30%;
      right: 0;

      width: 25%;
      height: 25%;

      transform: translateY(-50%);

      background: get-color('white', 900);
    }

    ._line {
      align-items: center;

      margin-bottom: 4.5rem;

      scroll-margin-top: 5rem;

      ._line__body {
        @include mq($until: md) {
          margin-bottom: 1rem;
        }
        h2,
        h3 {
          margin-bottom: 1rem;
        }
      }

      ._line__list {
        position: relative;
        z-index: 10;

        padding: 2rem;

        color: get-color('white');

        ul {
          padding: 0;
          padding-left: 1rem;
        }

        &::before {
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;

          width: 100vw;
          height: 100%;

          content: '';

          background: get-color('dark');
          box-shadow: 0 0 34px get-color('dark', 500, .1);
        }
      }
    }
  }
}
