body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  overflow-y: auto;

  &.noscroll {
    height: 100vh;
    overflow-y: hidden;
  }
}

a {
  text-decoration: none;
}

* {
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

//  //////////////////////////////////////////////
//  SECTION: DISABLE MOZILLA INNER FOCUS
::-moz-focus-inner {
  border: 0;
}

//  //////////////////////////////////////////////
//  SECTION: DISABLE MOZILLA INNER FOCUS
:root {
  --clr-primary: #{get-color('blue')};
}

//  //////////////////////////////////////////////
//  SECTION: DISABLE GOOGLE RECAPTCHA
.grecaptcha-badge {
  display: none !important;
}

//  //////////////////////////////////////////////
//  SECTION: DISABLE ANIMATION
@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    background-attachment: initial !important;
    transition-delay: 0s !important;
    transition-duration: 0s !important;
    animation-duration: 1ms !important;
    animation-delay: -1ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
  }
}
