#vLaMediation {
  section#content,
  section#duration,
  section#how,
  section#payment,
  section#agreement {
    position: relative;

    overflow: hidden;

    padding: 6rem 0;

    @include mq($until: md) {
      padding: 3rem 0;
    }

    ._background {
      position: absolute;
      top: 40%;
      right: 0;

      width: 25%;
      height: 50%;

      transform: translateY(-50%);

      background: get-color('white', 900);

      @include mq($until: md) {
        width: 50%;
      }
    }

    .row {
      align-items: center;

      ._content {
        h2,
        h3,
        li {
          margin-bottom: 1rem;
        }
      }
    }
  }

  section#duration,
  section#payment {
    ._background {
      right: auto;
      left: 0;
    }
  }
}
