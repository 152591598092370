#vVotreMediateur {
  section#content {
    position: relative;
    overflow: hidden;

    padding: 6rem 0;
    @include mq($until: lg) {
      padding: 3rem 0;
    }

    .row {
      align-items: center;
    }

    ._background {
      position: absolute;

      top: 30%;
      right: 0;
      height: 50%;
      width: 25%;
      transform: translateY(-50%);

      background: get-color('white', 900);
      @include mq($until: md) {
        width: 50%;
      }
    }

    h2 {
      margin-bottom: 1rem;
      color: get-color('dark');
    }

    img {
      width: 100%;
    }
  }

  section#formation {
    margin-bottom: 4rem;

    h3 {
      color: get-color('dark');
      margin-bottom: 1rem;
    }
  }

  section#conditions {
    margin-bottom: 2rem;

    .container {
      background: get-color('dark');
      color: get-color('white');
      padding: 2rem;

      h3 {
        margin: 0 auto;
        text-align: center;
        max-width: 715px;
      }
    }
  }
}
