#vLesHonoraires {
  .parallax-banner-layer-0 {
    background-position: bottom center !important;
  }

  #firstGroup,
  #secondGroup {
    position: relative;
    overflow: hidden;

    padding: 6rem 0;
    @include mq($until: md) {
      padding: 3rem 0;
    }

    ._background {
      position: absolute;

      top: 40%;
      right: 0;
      height: 50%;
      width: 25%;
      transform: translateY(-50%);

      background: get-color('white', 900);

      @include mq($until: md) {
        width: 50%;
      }
    }

    section {
      position: relative;
    }
  }

  #secondGroup {
    ._background {
      left: 0;
      right: auto;
      height: 25%;
    }
  }

  section#first {
    margin-bottom: 4rem;

    h2 {
      margin-bottom: 1rem;
    }

    p {
      margin-top: 1rem;
    }
  }

  section#price {
    margin-bottom: 4rem;

    h2 {
      margin-bottom: 1rem;
    }
  }

  section#insurance {
    margin-bottom: 4rem;
    h3 {
      margin-bottom: 1rem;
    }
  }

  section#help {
    margin-bottom: 4rem;

    .col-md-6 {
      h3 {
        margin-bottom: 1rem;
      }
    }
  }

  section#can {
    .col-12 {
      padding: 2rem;
      z-index: 10;

      &::before {
        position: absolute;
        content: '';

        top: 0;
        bottom: 0;
        right: 0;
        width: 100vw;

        background: get-color('white', 50);
        border-radius: 10px;
        box-shadow: 0 0 34px get-color('dark', 500, 0.1);
        z-index: -1;
      }
    }
  }
}
