.tRadio {
  .tRadio__group {
    .tRadio__group__value {
      display: flex;
      input {
        position: relative;

        flex-shrink: 0;
        display: block;
        height: 20px;
        width: 20px;

        &::after {
          content: '';
          position: absolute;
          top: 1.5px;
          left: 1.5px;
          height: calc(100% - 3px);
          width: calc(100% - 3px);
          border-radius: 999px;
          background: get-color('white', 100);
          box-shadow: 0 0 0 2px get-color('white', 100), 0 0 0 3px get-color('dark', 100);

          @include transition();
        }

        &:checked::after {
          background: get-color('dark', 100);
        }
      }

      label {
        padding-left: 1rem;
        display: block;

        @include transition();
      }

      & + .tRadio__group__value {
        margin-top: 1rem;
      }
    }

    &.tRadio__group--disabled {
      .tRadio__group__value {
        input::after {
          background: get-color('white', 500);
        }

        input:checked::after {
          background: get-color('blue', 900);
        }

        label {
          color: get-color('dark', 300);
        }
      }
    }
  }
  .tRadio__state {
    .tRadio__state__error {
      color: get-color('danger', 700);
    }
  }
}
