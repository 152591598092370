.cCookie {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999999999;
  display: flex;
  align-items: center;
  padding: 1rem;
  background: linear-gradient(to right, get-color('dark', 700), get-color('dark', 900));
  backdrop-filter: invert(20%) blur(10px);

  @include mq($until: md) {
    flex-direction: column;
  }

  p {
    flex: 1;
    padding: 0;
    margin: 0;
    color: get-color('white');
  }

  .cCookie__buttons {
    @include mq($from: md) {
      display: flex;
      flex-direction: column;
      margin-left: 1em;
      text-align: right;
    }

    @include mq($until: md) {
      width: 100%;
      margin-top: 1em;
      text-align: left;
    }

    & > div {
      width: 100%;
      @include mq($from: md) {
        display: flex;
        justify-content: flex-end;
      }

      .tButton {
        --clr-primary: #{get-color('white')};

        width: 100%;
      }

      & + div {
        margin-top: 0.5em;
      }
    }
  }
}
