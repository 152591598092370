#vE404 {
  ._content {
    padding: 1rem;
    z-index: 1;

    @include mq($from: md) {
      padding: 3rem;
    }

    display: flex;
    justify-content: center;

    ._body {
      color: get-color('white');
      text-align: center;
      @include mq($from: md) {
        width: 60%;
      }

      ._title {
        position: relative;
        padding: 3rem 0;
        padding-top: 0;

        ._title__sub {
          color: get-color('white', 900);
        }
      }

      p {
        position: relative;
        margin-top: 0;
        margin-bottom: 3rem;
      }
    }
  }
}
