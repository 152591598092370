@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@for $i from 1 through 9 {
  @keyframes breathing-#{$i} {
    0%,
    100% {
      filter: none;
    }

    50% {
      filter: brightness(10% * $i);
    }
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes slideDownCentered {
  0% {
    opacity: 0;
    transform: translateY(-2rem) translateX(-50%);
  }

  100% {
    opacity: 1;
    transform: translateX(-50%);
  }
}
