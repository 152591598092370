#vRendezVous {
  section#disclaimer {
    position: relative;
    overflow: hidden;

    padding: 6rem 0;
    @include mq($until: md) {
      padding: 3rem 0;
    }

    ._background {
      position: absolute;

      top: 40%;
      right: 0;
      height: 50%;
      width: 25%;
      transform: translateY(-50%);

      background: get-color('white', 900);

      @include mq($until: md) {
        width: 50%;
      }
    }

    .card {
      position: relative;
    }
  }

  section#success {
    margin-bottom: 6rem;
    @keyframes slidein {
      0% {
        opacity: 0;
        transform: translateY(-50%);
      }

      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    text-align: center;

    animation: slidein 0.3s ease;

    h2 {
      margin-bottom: 0.5rem;
    }
  }

  form {
    margin-bottom: 6rem;

    .tInput {
      margin-bottom: 1rem;
    }

    ._lastName {
      margin-bottom: 3rem;
    }

    ._label,
    ._contact {
      color: get-color('dark');
      margin-bottom: 0.75rem;
    }

    ._contact:last-child {
      margin-bottom: 0;
    }

    ._text {
      margin-bottom: 3rem;

      textarea {
        height: 5.2rem;
      }
    }

    ._conditions {
      margin-bottom: 2rem;
    }

    ._conditionsInfo {
      color: get-color('dark');
      margin-bottom: 1rem;

      svg {
        height: 20px;
        transform: translateY(4px);
      }

      a {
        margin-left: 1rem;
      }
    }

    .tButton {
      width: 100%;
    }

    ._error {
      margin-top: 1rem;
      padding: 0.5rem 1rem;
      display: inline-block;
      background: get-color('danger', 200);
      color: get-color('danger', 900);
      border-left: 4px solid get-color('danger', 900);
    }

    @include mq($until: md) {
      ._right {
        margin-top: 3rem;
      }
    }
  }

  section#map {
    margin-bottom: 2rem;

    .row {
      background: get-color('white', 50);
      border-radius: 10px;
      box-shadow: 0 0 34px get-color('dark', 500, 0.1);
      align-items: center;
      margin: 0;
    }

    .cGoogleMap {
      height: 100%;
      width: 100%;
      min-height: 375px;
      border-radius: 10px 0 0 10px;

      @include mq($until: md) {
        border-radius: 0 0 10px 10px;
      }
    }

    ._map {
      padding: 0;
    }

    ._info {
      padding: 2rem;

      h2,
      & > a {
        display: block;
        margin-bottom: 1rem;
      }
    }
  }
}
