//  ///////////////////////
//  SECTION: CONFIG
$T_BUTTON_ALIGNS: (left, center, right);

$T_BUTTON_COLORS: (
  '_fallback': 'dark',
  'dark': (
    color: get-color('white'),
    backgroundColor: get-color('dark', 600),
    shadow: get-color('dark', 500, 0.5),
    hovered: (
      backgroundColor: get-color('dark', 700),
    ),
    active: (
      backgroundColor: get-color('dark', 600),
      innerShadow: get-color('dark', 900),
    ),
    disabled: (
      color: get-color('dark', 200),
      backgroundColor: get-color('white', 900),
    ),
  ),
  'blue': (
    color: get-color('dark', 900),
    backgroundColor: get-color('blue', 500),
    shadow: get-color('dark', 500, 0.5),
    hovered: (
      backgroundColor: get-color('blue', 300),
    ),
    active: (
      backgroundColor: get-color('blue', 400),
      innerShadow: get-color('dark', 500),
    ),
    disabled: (
      color: get-color('dark', 300),
      backgroundColor: get-color('blue', 100),
    ),
  ),
  'white': (
    color: get-color('dark', 900),
    backgroundColor: get-color('white', 500),
    shadow: get-color('dark', 500, 0.5),
    hovered: (
      backgroundColor: get-color('blue', 500),
    ),
    active: (
      backgroundColor: get-color('blue', 600),
      innerShadow: get-color('dark', 500),
    ),
    disabled: (
      color: get-color('grey', 900),
      backgroundColor: get-color('grey', 500),
    ),
  ),
);

$T_BUTTON_SIZES: (
  '_fallback': 'm',
  's': (
    'padding': 0.75rem 1rem,
    'fontSize': 15px,
    'lineHeight': 20px,
    'shadow': 0,
  ),
  'm': (
    'padding': 1rem 2rem,
    'fontSize': 16px,
    'lineHeight': 21px,
    'shadow': 1,
  ),
  'l': (
    'padding': 1rem 2.5rem,
    'fontSize': 20px,
    'lineHeight': 29px,
    'shadow': 0,
  ),
);

//  ///////////////////////
//  SECTION: ANIMATIONS
@keyframes tButton__loader_spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

//  ///////////////////////
//  SECTION: CLASSES
.tButton {
  --clr-primary: #{config-get($T_BUTTON_COLORS, null, 'color')};
  @include transition();

  position: relative;
  display: inline-block;
  padding: 1rem 2rem;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  color: config-get($T_BUTTON_COLORS, null, 'color');
  cursor: not-allowed;
  background: config-get($T_BUTTON_COLORS, null, 'backgroundColor');
  border: 0;
  border-radius: 0;

  //  ///////////////////////
  //  SECTION: ICON & LOADER
  svg {
    position: absolute;
    display: inline-block;
    height: 1em;
    transform: translateY(2px) scale(1.25);

    &.tButton__loader {
      padding-right: 0.5em;
      path {
        transform-origin: center;
        animation: tButton__loader_spin 1s cubic-bezier(0.2, 0.5, 0.8, 0.5) infinite;
      }
    }
  }

  &.tButton--icon {
    &.tButton--iconSide-left > svg {
      left: 0.75rem;
    }

    &.tButton--iconSide-right > svg {
      right: 0.75rem;
    }
  }

  //  ///////////////////////
  //  SECTION: ALIGNS
  @each $key in $T_BUTTON_ALIGNS {
    &.tButton--align-#{$key} {
      text-align: $key;
    }
  }

  //  ///////////////////////
  //  SECTION: STATES
  &.tButton--disabled {
    $disabled_color: config-get($T_BUTTON_COLORS, null, 'disabled', 'color');
    $disabled_backgroundColor: config-get($T_BUTTON_COLORS, null, 'disabled', 'backgroundColor');

    --clr-primary: #{$disabled_color};

    color: $disabled_color;
    background: $disabled_backgroundColor;
  }

  &:not(.tButton--disabled):not(.tButton--loading) {
    $shadow: config-get($T_BUTTON_COLORS, null, 'shadow');
    $hovered_backgroundColor: config-get($T_BUTTON_COLORS, null, 'hovered', 'backgroundColor');
    $active_backgroundColor: config-get($T_BUTTON_COLORS, null, 'active', 'backgroundColor');
    $active_innerShadow: config-get($T_BUTTON_COLORS, null, 'active', 'innerShadow');

    cursor: pointer;
    filter: drop-shadow(0 2px 12px $shadow);

    &:hover {
      background: $hovered_backgroundColor;
    }

    &:active {
      background: $active_backgroundColor;
      box-shadow: $active_innerShadow;
    }
  }

  //  ///////////////////////
  //  SECTION: COLORS
  @each $key, $_ in $T_BUTTON_COLORS {
    @if $key != '_fallback' {
      $backgroundColor: config-get($T_BUTTON_COLORS, $key, 'backgroundColor');
      $color: config-get($T_BUTTON_COLORS, $key, 'color');
      $shadow: config-get($T_BUTTON_COLORS, $key, 'shadow');
      $hovered_backgroundColor: config-get($T_BUTTON_COLORS, $key, 'hovered', 'backgroundColor');
      $active_backgroundColor: config-get($T_BUTTON_COLORS, $key, 'active', 'backgroundColor');
      $active_innerShadow: config-get($T_BUTTON_COLORS, $key, 'active', 'innerShadow');
      $disabled_color: config-get($T_BUTTON_COLORS, $key, 'disabled', 'color');
      $disabled_backgroundColor: config-get($T_BUTTON_COLORS, $key, 'disabled', 'backgroundColor');

      $animationName: tButton--color-#{$key}_animation;

      @keyframes #{$animationName} {
        0%,
        100% {
          --clr-primary: #{$backgroundColor};

          background: $backgroundColor;
        }

        50% {
          --clr-primary: #{$hovered_backgroundColor};

          background: $hovered_backgroundColor;
        }
      }

      &.tButton--color-#{$key} {
        --clr-primary: #{$backgroundColor};

        background: $backgroundColor;
        color: $color;

        &.tButton--disabled {
          --clr-primary: #{$disabled_backgroundColor};

          color: $disabled_color;
          background: $disabled_backgroundColor;
        }

        &.tButton--loading {
          animation: $animationName 2s ease infinite;
        }

        &:not(.tButton--disabled):not(.tButton--loading) {
          filter: drop-shadow(0 2px 12px $shadow);

          &:hover {
            --clr-primary: #{$hovered_backgroundColor};

            background: $hovered_backgroundColor;
          }

          &:active {
            --clr-primary: #{$active_backgroundColor};

            background: $active_backgroundColor;
            box-shadow: inset 0 0 1em $active_innerShadow;
          }
        }
      }
    }
  }

  //  ///////////////////////
  //  SECTION: SIZES
  @each $key, $_ in $T_BUTTON_SIZES {
    @if $key != '_fallback' {
      &.tButton--size-#{$key} {
        $padding: config-get($T_BUTTON_SIZES, $key, 'padding');
        $fontSize: config-get($T_BUTTON_SIZES, $key, 'fontSize');
        $lineHeight: config-get($T_BUTTON_SIZES, $key, 'lineHeight');
        $shadow: config-get($T_BUTTON_SIZES, $key, 'shadow');

        padding: $padding;
        font-size: $fontSize;
        line-height: $lineHeight;

        @if ($shadow == 0) {
          filter: none !important;
        }

        &.tButton--icon {
          $paddingIcon: nth($padding, 1);

          &.tButton--iconSide-left {
            padding-left: $paddingIcon + 2rem;

            svg {
              right: auto;
              left: $paddingIcon + 0.5rem;
            }
          }

          &.tButton--iconSide-right {
            padding-right: $paddingIcon + 2rem;

            svg {
              right: $paddingIcon + 0.5rem;
              left: auto;
            }
          }
        }
      }
    }
  }
}
