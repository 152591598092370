@import '~bootstrap/scss/bootstrap-grid';

@import 'mq';

@import './functions';

@import './variables';
@import './colors';

@import './mixins';

@import './theme';
@import './animations';

@import './misc';

@import './components/buttons';
@import './components/card';
@import './components/legals';
@import './components/link';

@import '../components/all';
@import '../theme/all';
@import '../views/all';
