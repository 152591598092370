#vLesAvantages {
  section#content {
    position: relative;
    overflow: hidden;

    padding: 6rem 0;
    @include mq($until: lg) {
      padding: 3rem 0;
    }

    ._background--first,
    ._background--second {
      position: absolute;

      bottom: 40%;
      right: 0;
      height: 50%;
      width: 25%;
      transform: translateY(50%);

      background: get-color('white', 900);
    }

    ._background--second {
      left: 0;
      right: auto;
    }

    h2 {
      position: relative;
    }

    @include mq($until: lg) {
      .container {
        padding: 0;

        h2 {
          margin: 0 15px;
        }
      }
    }

    ._card {
      position: relative;
      z-index: 10;

      display: flex;

      margin-top: 2.5rem;
      scroll-margin-top: 5rem;
      margin-left: 10rem;
      @include mq($until: xl) {
        margin-left: 3rem;
      }
      @include mq($until: lg) {
        margin-left: 1rem;
        flex-direction: column;
      }

      &::before {
        position: absolute;
        content: '';

        top: 0;
        left: 0;
        height: 100%;
        width: 100vw;

        background: get-color('white', 50);
        box-shadow: 0 0 34px get-color('dark', 500, 0.1);
        border-radius: 10px 0 0 10px;

        z-index: -1;
      }

      ._card__title {
        display: flex;
        flex-direction: column;

        border-radius: 10px;
        box-shadow: 0 0 34px get-color('dark', 500, 0.1);

        ._card__title__body {
          height: 100%;

          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;

          border-radius: 10px 10px 0 0;

          @include mq($until: lg) {
            height: 200px;
          }
        }

        ._card__title__footer {
          color: get-color('dark');

          text-align: center;
          padding: 2rem;
        }

        div {
          width: 330px;
          @include mq($until: lg) {
            width: 100%;
          }
        }

        @include mq($until: sm) {
          border-radius: 10px 0 0 10px;

          ._card__title__body {
            border-radius: 10px 0 0;
          }
        }
      }

      ._card__body {
        padding: 4rem 0 4rem 2rem;
        @include mq($until: lg) {
          padding: 2rem;
        }

        p::first-letter {
          float: left;
          font-size: 43px;
          line-height: 42px;
          margin-right: 0.5rem;
        }
      }

      &._card--left {
        border-radius: 0 10px 10px 0;

        margin-left: 0;
        margin-right: 10rem;
        @include mq($until: xl) {
          margin-right: 3rem;
        }
        @include mq($until: lg) {
          margin-right: 1rem;
        }

        &::before {
          left: auto;
          right: 0;
          border-radius: 0 10px 10px 0;
        }

        @include mq($from: lg) {
          ._card__title {
            order: 2;
          }

          ._card__body {
            order: 1;

            padding: 4rem 2rem 4rem 0;
          }
        }

        @include mq($until: sm) {
          ._card__title {
            border-radius: 0 10px 10px 0;

            ._card__title__body {
              border-radius: 0 10px 0 0;
            }
          }
        }
      }
    }
  }
}
