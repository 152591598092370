#vIndex {
  section#categories {
    position: relative;
    overflow: hidden;

    padding: 6rem 0;
    @include mq($until: md) {
      padding: 3rem 0;
    }

    ._background {
      position: absolute;

      top: 0;
      right: 0;
      height: 50%;
      width: 50%;

      background: get-color('white', 900);
    }

    .col-12 {
      @include mq($from: md) {
        padding: 0 1rem;
      }

      ._category {
        --clr-primary: #{get-color('dark')};
        @include transition();

        display: block;
        width: 100%;

        padding: 2rem;
        background: get-color('dark');
        text-align: center;

        font-size: 21px;
        font-weight: 500;
        line-height: 27px;
        color: get-color('white');

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }

  section#perks {
    position: relative;
    overflow: hidden;

    padding: 6rem 0;
    @include mq($until: md) {
      padding: 3rem 0;
    }

    ._background {
      position: absolute;

      top: 50%;
      left: 0;
      height: 50%;
      width: 25%;
      transform: translateY(-50%);

      background: get-color('white', 900);
    }

    h2 {
      text-align: center;
      margin-bottom: 4rem;
    }

    .col-12 {
      @include mq($from: md) {
        padding: 0 1rem;
      }

      ._perk {
        display: block;
        height: 100%;

        background: get-color('white', 50);
        color: get-color('dark');

        box-shadow: 0 0 34px rgba(38, 88, 145, 0.1);
        border-radius: 10px;

        text-align: center;

        ._perk__body {
          width: 100%;
          padding-top: 59%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 101%;
          border-radius: 10px 10px 0 0;

          @include transition();
        }

        &:hover ._perk__body {
          background-size: 110%;
        }

        ._perk__footer {
          padding: 1rem;
          height: 100%;
        }
      }
    }
  }

  section#more {
    position: relative;
    overflow: hidden;

    padding: 5.5rem 0;

    text-align: center;

    ._background {
      position: absolute;

      top: 0;
      right: 0;
      height: 100%;
      width: 25%;

      background: get-color('white', 900);
    }

    .container {
      .row {
        height: 100%;
      }

      h2,
      h3 {
        margin-bottom: 2rem;
      }

      img {
        position: relative;
        width: 100%;
      }

      @include mq($until: lg) {
        flex-direction: column;

        & > .row > .col-12:last-child {
          order: 1;
          margin-bottom: 2rem;

          img {
            margin: 0 auto;
            width: 100%;
          }
        }

        .col-12:first-child {
          order: 2;
        }
      }
    }
  }
}
