$COLORS: (
  'white': (
    100: #fff,
    200: #fcfdff,
    300: #fafcff,
    400: #f8fbff,
    500: #f5f9ff,
    600: #f2f7ff,
    700: #eff5ff,
    800: #ecf3ff,
    900: #e9f1ff,
  ),
  'grey': (
    500: #c4c4c4,
  ),
  'black': (
    500: #000,
  ),
  'blue': (
    100: #abddf2,
    200: #9dd8f0,
    300: #8fd2ee,
    400: #81cdec,
    500: #73c7ea,
    600: #68acd3,
    700: #5c93bb,
    800: #517ca4,
    900: #456a8c,
  ),
  'dark': (
    100: #7d9bbd,
    200: #678ab2,
    300: #5179a7,
    400: #3c699c,
    500: #265891,
    600: #224f83,
    700: #1e4674,
    800: #1b3e66,
    900: #173557,
  ),
  'success': (
    500: #28a745,
  ),
  'danger': (
    500: #fa6e6e,
  ),
  'warning': (
    500: #fec109,
  ),
  'info': (
    500: #17a1b8,
  ),
);

//  ///////////////////////
//  SECTION: FUNCTIONS
@function get-color($key: 'blue', $shade: 500, $rgba: 1) {
  $color: map-get($COLORS, $key);

  @if (map-has-key($color, $shade)) {
    $color: map-get($color, $shade);
  } @else {
    $color: map-get($color, 500);

    @if ($shade < 500) {
      $color: lighten($color, ((1 - $shade / 500) * 100) / 2.7);
    } @else {
      @if ($shade > 500) {
        $color: shade($color, ((($shade - 500) / 500) * 100) / 2 * 1%);
      }
    }
  }

  @return rgba($color, $rgba);
}

//  ///////////////////////
//  SECTION: UTILS
@each $key, $value in $COLORS {
  $color: get-color($key, 500);

  .color-#{$key} {
    --clr-primary: #{$color};

    color: $color;
  }

  .bg-#{$key} {
    background-color: $color;
  }

  .border-#{$key} {
    border-color: get-color($key, 500);
  }

  .svg-fill-#{$key} {
    fill: get-color($key, 500);
  }

  .svg-stroke-#{$key} {
    stroke: get-color($key, 500);
  }

  @each $shade in (50, 100, 200, 300, 400, 500, 600, 700, 800, 900) {
    $color: get-color($key, $shade);

    .color-#{$key}-#{$shade} {
      --clr-primary: #{$color};

      color: $color;
    }

    .bg-#{$key}-#{$shade} {
      background-color: $color;
    }

    .border-#{$key}-#{$shade} {
      border-color: $color;
    }

    .svg-fill-#{$key}-#{$shade} {
      fill: $color;
    }

    .svg-stroke-#{$key}-#{$shade} {
      stroke: $color;
    }
  }
}
