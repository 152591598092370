@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&family=Roboto:wght@400;500;700&display=swap');

html {
  font-size: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: get-color('dark', 900);
  background: get-color('dark', 800);

  #root {
    background: get-color('white');
  }
}

h1 {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 67px;
  font-style: normal;
  font-weight: 400;
  line-height: 88px;

  @include mq($until: md) {
    font-size: 52px;
    line-height: 60px;
  }
}

h2 {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
}

h3 {
  font-family: 'Roboto', sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
}

p {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

small {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 17px;
}

.content {
  margin-top: $NAVBAR_HEIGHT;
  @include mq($from: md, $until: xl) {
    margin-top: 100px;
  }

  .view {
    background: get-color('white');
  }
}

//  ///////////////////////
//  SECTION: OUTLINE
a,
button {
  outline: 3px dotted transparent;
  outline-offset: 3px;

  &:focus {
    outline-color: var(--clr-primary);
  }

  @include transition();
}

//  ///////////////////////
//  SECTION: UTILS
@for $i from 1 through 9 {
  .font-weight-#{$i * 100} {
    font-weight: $i * 100;
  }
}

@each $i in (center, right, left, justify, start, end) {
  .text-align-#{$i} {
    text-align: $i;
  }
}
