.cLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .cLoading__loader {
    @keyframes cLoading__loader__spinner {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    width: 200px;
    height: 200px;
    margin-top: 5rem;
    margin-bottom: 5rem;
    border: 10px solid rgba(get-color('dark'), 0.4);
    border-top: 10px solid get-color('dark');
    border-radius: 50%;
    animation: 1s linear infinite cLoading__loader__spinner;
  }
}
